import React, { useEffect, useState } from 'react';

import ApiUser from '@/api/user';
import { getTokenConfig } from '@/common/generic-ft-nft-holding-helper';
import { isBrowser } from '@/common/utils';
import MetaTags from '@/components/meta-tags';

import '@/assets/scss/app.scss';

function IndexPage() {
  const lowerCaseParams = isBrowser()
    ? new URLSearchParams(window.location.search)
    : [];
  const contract = isBrowser() ? lowerCaseParams.get('contract') : '';
  const [referralProgramData, setReferralProgramData] = useState<string>('');

  const getReferralRewards = async () => {
    const userId = await ApiUser.getReferralUserData(lowerCaseParams.get('r'));
    const data = await ApiUser.getReferralProgramData(userId);
    setReferralProgramData(JSON.stringify(data, null, 2));
  };

  useEffect(() => {
    getReferralRewards();
  }, []);

  return (
    <div>
      {' '}
      Contract - {JSON.stringify(getTokenConfig(contract), null, 2)} <br />{' '}
      <br />
      <br />
      Referral - {referralProgramData}{' '}
    </div>
  );
}

export function Head() {
  return (
    <>
      <MetaTags />
    </>
  );
}

export default IndexPage;
